import LogRocket from 'logrocket';
import config from '.';

const refactorReqBodyProperty = (request: any, property: string, content = 'sensitive  data.') => {
  if (request.body && request.body.indexOf(property) >= 0) {
    const body = JSON.parse(request.body);

    body[property] = content;
    request.body = JSON.stringify(body);
  }

  return request.body;
};

const propertiesToRefactor = ['password', 'token'];

export const logrocketInit = () => {
  LogRocket.init(config.logrocketAppId, {
    network: {
      requestSanitizer: (request) => {
        request.headers['Authorization'] = undefined;

        if (request.url.indexOf('socket.io') >= 0) {
          const url = request.url.split('=')[0];

          request.url = url;
        }

        for (const property of propertiesToRefactor) {
          request.body = refactorReqBodyProperty(request, property);
        }

        return request;
      },
      responseSanitizer: (response) => {
        response.headers['Authorization'] = undefined;

        return response;
      },
    },
  });
};
