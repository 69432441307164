import { createAuthAxios } from '@hooks/axios.hook';
import { BaseResponse, makeAuthMutation, makeMutation } from '@services/entites/base-response.entity';
import { globalStoreCookie } from '@store/global-store-cookie.service';
import {
  AddMembersInGroupDTO,
  CreateGroupDTO,
  CreateWorkspaceDTO,
  DeleteGroupDTO,
  InviteNormalUserDTO,
  RemoveWorkspaceMemberDTO,
  SelectWorkspaceDTO,
  UpdateGroupDTO,
  UpdateGroupMemberRoleDTO,
  UpdateWokspaceSidebarModuleDTO,
  UpdateWorkspaceDTO,
  UpdateWorkspaceRoleDTO,
  UploadWorkspaceLogoDTO,
  WorkspaceIdDTO,
} from './dto/workpsace.dto';
import { SelectedWorkspaceEntity, WorkspaceEntity } from './entity/workspace.entity';

export const FETCH_WORKSPACES = 'FETCH_WORKSPACES';

const useCreateWorkspace = makeAuthMutation((http, data: CreateWorkspaceDTO) =>
  http.post<WorkspaceEntity>(`workspace/create`, data)
);

// global-store initialization
const useSelectWorkspace = makeMutation(({ workspaceID, ...tokens }: SelectWorkspaceDTO) => {
  const axios = createAuthAxios({
    getAccessToken: () => tokens.accessToken,
    getRefreshToken: () => tokens.refreshToken,
    setAccessToken: (newAccessToken) => {
      globalStoreCookie.setGlobalStoreCookie((p) => {
        if (!p) {
          return null;
        }
        return {
          ...p,
          accessToken: newAccessToken,
        };
      });
    },
    onLogout: () => {
      globalStoreCookie.setGlobalStoreCookie(() => null);
    },
    baseURL: import.meta.env.REACT_APP_API_URL,
  });

  return axios.get<BaseResponse<SelectedWorkspaceEntity>>(`/workspace/${workspaceID}/select-workspace`);
});

const useUpdateWorkspaceLogo = makeAuthMutation((http, dto: UploadWorkspaceLogoDTO) => {
  const formData = new FormData();
  formData.set('logo', dto.file);
  return http.post<WorkspaceEntity>(`workspace/${dto.workspaceID}/update-logo`, formData);
});

const useUpdateWorkspace = makeAuthMutation((http, { dto, workspaceID }: UpdateWorkspaceDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/update-workspace`, dto)
);

const useDeleteWorkspace = makeAuthMutation((http, { workspaceID }: WorkspaceIdDTO) =>
  http.delete<WorkspaceEntity>(`/workspace/${workspaceID}/delete`)
);
const useUpdateUserWorkspaceRole = makeAuthMutation((http, { workspaceID, payload }: UpdateWorkspaceRoleDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/change-workspace-member-role`, payload)
);
const useRemovePendingMemberFromWorkspace = makeAuthMutation(
  (http, { member, workspaceID }: RemoveWorkspaceMemberDTO) =>
    http.put<WorkspaceEntity>(`workspace/${workspaceID}/remove-pending-user`, { email: member })
);
const useRemoveMemberFromWorkspace = makeAuthMutation((http, { workspaceID, member }: RemoveWorkspaceMemberDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/remove-member-from-workspace`, { member })
);

const useInviteNormalUser = makeAuthMutation((http, { workspaceID, users }: InviteNormalUserDTO) =>
  http.post<WorkspaceEntity>(`/workspace/${workspaceID}/invite-members`, { users })
);
/*====================================== group crud service================================================  */
const useCreateGroup = makeAuthMutation((http, { workspaceID, dto }: CreateGroupDTO) =>
  http.post<WorkspaceEntity>(`workspace/${workspaceID}/create-group`, dto)
);
const useUpdateGroup = makeAuthMutation((http, { workspaceID, dto }: UpdateGroupDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/update-group`, dto)
);
const useDeleteGroup = makeAuthMutation((http, { workspaceID, groupID }: DeleteGroupDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/delete-group`, { groupID })
);
const useUploadGroupLogo = makeAuthMutation((http, dto: UploadWorkspaceLogoDTO) => {
  const formData = new FormData();
  formData.set('logo', dto.file);
  return http.post<string>(`workspace/${dto.workspaceID}/group-logo`, formData);
});
const useUpdateGroupMemberRole = makeAuthMutation((http, { workspaceID, dto }: UpdateGroupMemberRoleDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/change-group-member-role`, dto)
);

const useAddMemberInGroup = makeAuthMutation((http, { workspaceID, dto }: AddMembersInGroupDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/add-members-to-group`, dto)
);

const useRemoveMembersFromGroup = makeAuthMutation((http, { workspaceID, dto }: AddMembersInGroupDTO) =>
  http.put<WorkspaceEntity>(`workspace/${workspaceID}/remove-members-from-group`, dto)
);

const useUpdateWorkspaceSidebar = makeAuthMutation((http, { workspaceID, dto }: UpdateWokspaceSidebarModuleDTO) =>
  http.patch<WorkspaceEntity>(`workspace/${workspaceID}/sidebar`, dto)
);

export const workspaceService = {
  useUpdateWorkspaceLogo,
  useUpdateWorkspace,
  useDeleteWorkspace,
  useRemoveMemberFromWorkspace,
  useRemovePendingMemberFromWorkspace,
  useUpdateUserWorkspaceRole,
  useInviteNormalUser,
  useCreateGroup,
  useUpdateGroup,
  useDeleteGroup,
  useUploadGroupLogo,
  useUpdateGroupMemberRole,
  useAddMemberInGroup,
  useRemoveMembersFromGroup,
  useCreateWorkspace,
  useSelectWorkspace,
  useUpdateWorkspaceSidebar,
};
