// src/Layout.js
import { Col, Grid, Group, Skeleton, Stack } from '@getonnet/tixio-ui-core';

export const ModuleSkeleton = () => {
  return (
    <Grid
      sx={{ width: '100%' }}
      gutter={0}
    >
      <Col span={2}>
        {/* Sidebar */}
        <div style={{ backgroundColor: '#F9FAFB', height: '100vh', padding: '20px' }}>
          {/* Sidebar content goes here */}
          <Stack
            spacing={16}
            mt={80}
          >
            <Skeleton
              width="100%"
              height={40}
            />
            <Skeleton
              width="80%"
              height={40}
            />
            <Skeleton
              width="90%"
              height={40}
            />
            <Skeleton
              width="100%"
              height={40}
            />
            <Skeleton
              width="100%"
              height={40}
            />
          </Stack>
        </div>
      </Col>
      <Col span={10}>
        {/* Topbar */}
        <div style={{ backgroundColor: '#F9FAFB', padding: '6px 36px' }}>
          <Group
            align="center"
            position="apart"
          >
            <Skeleton
              width={160}
              height={32}
            />
            <Skeleton
              circle
              height={50}
            />
          </Group>
          {/* Topbar content goes here */}
        </div>
        {/* Main Content */}
        <div style={{ padding: '20px' }}>{/* Main layout area */}</div>
      </Col>
    </Grid>
  );
};
