import { Container, Grid } from '@getonnet/tixio-ui-core';
import { replaceWindowURL } from '@utils';
import { FC } from 'react';
import { ErrorPage } from '../../../components/ErrorPage/ErrorPage';

export const AppSumoError: FC<{ error: Error }> = ({ error }) => {
  return (
    <Container>
      <Grid
        justify="center"
        align="center"
        sx={{ minHeight: '100vh', minWidth: '100vw' }}
      >
        <Grid.Col>
          <ErrorPage
            title="Something Went Wrong"
            message={error.message}
            buttonText="Go back"
            buttonHandler={() => replaceWindowURL('https://tixio.io')}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export const GlobalErrorPage: FC<{ error: Error; stack?: string }> = ({ error, stack }) => {
  return (
    <Container>
      <Grid
        justify="center"
        align="center"
        sx={{ minHeight: '100vh', minWidth: '100vw' }}
      >
        <Grid.Col>
          <ErrorPage
            title="Something Went Wrong"
            message={
              import.meta.env.DEV
                ? error.message
                : 'Sorry, Something went wrong on our end 🥺 please reload the page or go back 😭'
            }
            buttonText="Reload page"
            buttonHandler={() => window.location.reload()}
            stack={stack}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
};
