import config from '@config';
import { globalStore } from '@store/global-store.provider';
import axios from 'axios';

export const isTokenValid = (token: string) => {
  const [, actualToken] = token.split('.');

  if (!actualToken) {
    throw new Error('Invalid token');
  }

  const parsedToken = JSON.parse(window.atob(actualToken)) as { exp?: number };

  if (!parsedToken.exp) {
    return false;
  }

  const now = (new Date().getTime() + 10_000) / 1000;

  return parsedToken.exp > now;
};

export const trySafe = async <ReturnType>(params: {
  fn: () => Promise<ReturnType> | ReturnType;
  onError?: (error: any) => void;
}): Promise<ReturnType | null> => {
  try {
    return await params.fn();
  } catch (error) {
    if (params.onError) {
      params.onError(error);
    }
    return null;
  }
};

export const getAccessToken = async (): Promise<string | null> => {
  const state = globalStore.getState();
  const accessToken = state.accessToken;
  const refreshToken = state.refreshToken;

  if (!accessToken || !refreshToken) {
    globalStore.onLogout();
    return null;
  }

  const isAccessTokenValid = isTokenValid(accessToken);
  const isRefreshTokenValid = isTokenValid(refreshToken);

  if (isAccessTokenValid) {
    return accessToken;
  }

  if (!isRefreshTokenValid) {
    globalStore.onLogout();
    return null;
  }

  const response = await axios
    .post(`${config.apiUrl}/auth/refresh`, {
      refreshToken,
    })
    .then((response) => {
      const token = response.data.data.accessToken;
      globalStore.setAccessToken(token);
      return token;
    })
    .catch((error) => {
      if (!error.status) {
        throw error;
      }

      globalStore.onLogout();
      return null;
    });

  return response;
};
