import styled, { keyframes } from 'styled-components';
import Utils from '../../utils';

const load8 = keyframes`
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
`;

export const LoaderWrapper = styled.div<{ size?: string }>`
  width: ${(props) => (props.size ? props.size : '32px')};
  height: ${(props) => (props.size ? props.size : '32px')};
  margin: 0 auto;
`;

export const LoaderSpinner = styled.div<{ border?: string; size?: string; color?: string }>`
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: ${(props) => (props.border ? props.border : '6px')} solid
    ${(props) =>
      props.color ? Utils.formatColor(props.color, '0.2') : Utils.formatColor(props.theme.colors.cardColor, '0.2')};
  border-right: ${(props) => (props.border ? props.border : '6px')} solid
    ${(props) =>
      props.color ? Utils.formatColor(props.color, '0.2') : Utils.formatColor(props.theme.colors.cardColor, '0.2')};
  border-bottom: ${(props) => (props.border ? props.border : '6px')} solid
    ${(props) =>
      props.color ? Utils.formatColor(props.color, '0.2') : Utils.formatColor(props.theme.colors.cardColor, '0.2')};
  border-left: ${(props) => (props.border ? props.border : '6px')} solid
    ${(props) =>
      props.color ? Utils.formatColor(props.color, '1') : Utils.formatColor(props.theme.colors.cardColor, '1')};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${load8} 1.1s infinite linear;
  animation: ${load8} 1.1s infinite linear;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  &:after {
    border-radius: 50%;
    width: ${(props) => (props.size ? props.size : '32px')};
    height: ${(props) => (props.size ? props.size : '32px')};
  }
`;
