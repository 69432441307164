import { useAuthAxiosMain, useChatAxios, usePublicAxiosMain } from '@hooks/axios.hook';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance, AxiosResponse } from 'axios';

type SuccessResponse<T> = {
  success: true;
  data: T;
  message: null;
};

type FailedResponse = {
  success: false;
  message: string;
};

export type BaseResponse<T> = SuccessResponse<T> | FailedResponse;

class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiError';
  }
}

export const processResponse = async <T>(fn: () => Promise<AxiosResponse<BaseResponse<T>>>, fallback?: T) => {
  try {
    const res = await fn();

    if (res.data.success) {
      return res.data.data;
    }

    throw new ApiError(res.data.message);
  } catch (error) {
    console.log(error);

    if (fallback) {
      return fallback;
    }

    if (error instanceof ApiError) {
      throw error;
    }

    throw new ApiError('Network error');
  }
};

type QueryOptions<T> = Omit<UseQueryOptions<T>, 'queryKey'> & {
  queryKey?: string[];
};

type MutationOptions<T> = UseMutationOptions<T>;

export const makeQuery = <Response, Params extends readonly any[]>(
  fn: (...args: Params) => Promise<AxiosResponse<Response>>,
  key: string[]
) => {
  type WithOptions = [...Params, options?: QueryOptions<Response>];

  return (...args: WithOptions) => {
    const argLength = fn.length - 1;
    const options = argLength === args.length ? {} : (args.pop() as QueryOptions<Response> | undefined) || {};

    return useQuery({
      queryKey: key,
      queryFn: () => processResponse(() => fn(...(args as any)) as Promise<AxiosResponse<BaseResponse<Response>>>),
      ...options,
    });
  };
};

export const makeMutation = <Response, Variables>(
  fn: (data: Variables) => Promise<AxiosResponse<BaseResponse<Response>>>
) => {
  return (options?: MutationOptions<Response>) => {
    return useMutation<Response, unknown, Variables>({
      mutationFn: (data) => processResponse(() => fn(data)),
      ...(options as any),
    });
  };
};

export const makeAuthQuery = <Response, Params extends readonly any[]>(
  fn: (axios: AxiosInstance, ...args: Params) => Promise<AxiosResponse<Response>>,
  key: string[]
) => {
  type WithOptions = [...Params, options?: QueryOptions<Response>];

  return function GeneratedUseQueryHook(...args: WithOptions) {
    const argLength = fn.length - 1;
    const options = argLength === args.length ? {} : (args.pop() as QueryOptions<Response> | undefined) || {};

    const axios = useAuthAxiosMain();
    return useQuery({
      queryKey: key,
      queryFn: () =>
        processResponse(
          () => fn(axios, ...(args as unknown as Params)) as Promise<AxiosResponse<BaseResponse<Response>>>
        ),
      ...options,
    });
  };
};
export const makeAuthMutation = <Response, Variables>(
  fn: (axios: AxiosInstance, data: Variables) => Promise<AxiosResponse<Response>>
) => {
  return function GeneratedUseMutationHook(options?: MutationOptions<Response>) {
    const axios = useAuthAxiosMain();
    return useMutation<Response, unknown, Variables>({
      mutationFn: (data) => processResponse(() => fn(axios, data) as Promise<AxiosResponse<BaseResponse<Response>>>),
      ...(options as any),
    });
  };
};

export const makePublicQuery = <Response, Params extends readonly any[]>(
  fn: (axios: AxiosInstance, ...args: Params) => Promise<AxiosResponse<Response>>,
  key: string[]
) => {
  type WithOptions = [...Params, options?: QueryOptions<Response>];

  return function GeneratedUseQueryHook(...args: WithOptions) {
    const argLength = fn.length - 1;
    const options = argLength === args.length ? {} : (args.pop() as QueryOptions<Response> | undefined) || {};

    const axios = usePublicAxiosMain();
    return useQuery({
      queryKey: key,
      queryFn: () =>
        processResponse(
          () => fn(axios, ...(args as unknown as Params)) as Promise<AxiosResponse<BaseResponse<Response>>>
        ),
      ...options,
    });
  };
};

export const makePublicMutation = <Response, Variables>(
  fn: (axios: AxiosInstance, data: Variables) => Promise<AxiosResponse<Response>>
) => {
  return function GeneratedUseMutationHook(options?: MutationOptions<Response>) {
    const axios = usePublicAxiosMain();
    return useMutation<Response, unknown, Variables>({
      mutationFn: (data) => processResponse(() => fn(axios, data) as Promise<AxiosResponse<BaseResponse<Response>>>),
      ...(options as any),
    });
  };
};

export const makeChatAuthQuery = <Response, Params extends readonly any[]>(
  fn: (axios: AxiosInstance, ...args: Params) => Promise<AxiosResponse<Response>>,
  key: string[]
) => {
  type WithOptions = [...Params, options?: QueryOptions<Response>];

  return function GeneratedUseQueryHook(...args: WithOptions) {
    const argLength = fn.length - 1;
    const options = argLength === args.length ? {} : (args.pop() as QueryOptions<Response> | undefined) || {};

    const axios = useChatAxios();
    return useQuery({
      queryKey: key,
      queryFn: () =>
        processResponse(
          () => fn(axios, ...(args as unknown as Params)) as Promise<AxiosResponse<BaseResponse<Response>>>
        ),
      ...options,
    });
  };
};

export const makeChatMutation = <Response, Variables>(
  fn: (axios: AxiosInstance, data: Variables) => Promise<AxiosResponse<Response>>
) => {
  return function GeneratedUseMutationHook(options?: MutationOptions<Response>) {
    const axios = useChatAxios();
    return useMutation<Response, unknown, Variables>({
      mutationFn: (data) => processResponse(() => fn(axios, data) as Promise<AxiosResponse<BaseResponse<Response>>>),
      ...(options as any),
    });
  };
};
