import { MAINTENANCE_BYPASS } from '@containers/TaskModule/utils/localstorage.utils';
import axios from 'axios';

export const fetchIsInMaintainance = async (): Promise<boolean> => {
  try {
    const maintenancePromise = await axios.get(import.meta.env.REACT_APP_API_URL + '/maintenance');
    const maintenanceEnabled = maintenancePromise.data.data.enabled;

    if (!maintenanceEnabled) return false;

    // bypass maintanance
    const maintenanceBypassKey = localStorage.getItem(MAINTENANCE_BYPASS);

    if (maintenanceBypassKey === `${MAINTENANCE_BYPASS}_${new Date().getDate()}`) return false;

    (window as any).bypassMaintenance = () => {
      localStorage.setItem(MAINTENANCE_BYPASS, `${MAINTENANCE_BYPASS}_${new Date().getDate()}`);
      window.location.reload();
    };

    return maintenanceEnabled;
  } catch (error) {
    return true;
  }
};
