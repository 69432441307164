import { BaseResponse } from '@services/entites/base-response.entity';
import { GlobalStore, useGlobalStore } from '@store/global.store';
import { isTokenValid } from '@utils/getToken';
import axios, { AxiosInstance } from 'axios';
import { useMemo } from 'react';

type CreateAxiosPayload = {
  getAccessToken: () => string;
  getRefreshToken: () => string;
  setAccessToken: (token: string) => void;
  onLogout: () => void;
  extraHeaders?: () => Record<string, string>;
  baseURL: string;
};

export const createAuthAxios = (payload: CreateAxiosPayload) => {
  const { getAccessToken, getRefreshToken, setAccessToken, onLogout, extraHeaders, baseURL } = payload;
  const instance = axios.create({
    baseURL: baseURL,
    validateStatus: () => true,
  });

  instance.interceptors.request.use(async (config) => {
    console.log('INTERCEPTOR CALLED-------');
    const accessToken = getAccessToken();

    if (!accessToken) {
      throw new Error('Not authenticated');
    }
    const isAccessTokenValid = isTokenValid(accessToken);

    if (isAccessTokenValid) {
      config.headers.setAuthorization(`Bearer ${accessToken}`);

      Object.entries(extraHeaders?.() ?? {}).forEach(([key, value]) => {
        config.headers.set(key, value as any);
      });

      return config;
    }

    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      onLogout();
      throw new Error('Not authenticated');
    }

    const isRefreshTokenValid = isTokenValid(refreshToken);

    if (!isRefreshTokenValid) {
      onLogout();
      throw new Error('Refresh token expired');
    }

    console.log('REFRESHING TOKEN');

    const data = await axios
      .post<BaseResponse<{ accessToken: string }>>(`${import.meta.env.REACT_APP_API_URL}/auth/refresh`, {
        refreshToken,
      })
      .then((res) => {
        if (!res.data.success) {
          onLogout();
          return null;
        }

        return res.data.data;
      })
      .catch((error) => {
        if (!error.status) {
          //network error
          return null;
        }

        //api error
        onLogout();
        return null;
      });

    if (!data) {
      throw new Error('Failed to refresh token due to network error');
    }

    console.log('REFRESHED TOKEN should be string', data.accessToken);
    config.headers.setAuthorization(`Bearer ${data.accessToken}`);

    Object.entries(extraHeaders?.() ?? {}).forEach(([key, value]) => {
      config.headers.set(key, value as any);
    });

    setAccessToken(data.accessToken);

    return config;
  });

  instance.interceptors.response.use((response) => {
    if (response.status === 401) {
      onLogout();
    }

    return response;
  });

  return instance;
};

const auth_Axios_cache_map = new Map<GlobalStore, AxiosInstance>();

export const useAuthAxiosMain = () => {
  const { store } = useGlobalStore();

  const instance = auth_Axios_cache_map.get(store);
  if (instance) return instance;

  const newInstance = createAuthAxios({
    getAccessToken: () => store.getState().accessToken,
    getRefreshToken: () => store.getState().refreshToken,
    onLogout: () => store.onLogout(),
    setAccessToken: (token) => store.setAccessToken(token),
    baseURL: import.meta.env.REACT_APP_API_URL,
  });

  auth_Axios_cache_map.set(store, newInstance);

  return newInstance;
};

export const usePublicAxiosMain = () => {
  const { store } = useGlobalStore();

  const instance = useMemo(() => {
    const ax = axios.create({
      baseURL: import.meta.env.REACT_APP_API_URL,
      validateStatus: () => true,
    });

    ax.interceptors.request.use((config) => {
      config.headers['Accept-Language'] = store.getState().language;

      return config;
    });

    return ax;
  }, [store]);

  return instance;
};

export const useChatAxios = () => {
  const { store } = useGlobalStore();

  return useMemo(() => {
    return createAuthAxios({
      getAccessToken: () => store.getState().accessToken,
      getRefreshToken: () => store.getState().refreshToken,
      onLogout: () => store.onLogout(),
      setAccessToken: (token) => store.setAccessToken(token),
      baseURL: import.meta.env.REACT_APP_CHAT_API_URL,
    });
  }, [store]);
};
