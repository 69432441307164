import { createGlobalStyle, css } from 'styled-components';
import Utils from '../utils';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    border: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    box-sizing: border-box;
    scroll-behavior: smooth;
    .excalidraw.excalidraw-modal-container {
      z-index: 999;
    }
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  .manrope-font {
    font-family: "Manrope", -apple-system, "Segoe UI", sans-serif;
  }

  body div[role="textbox"] {
    outline:none !important
  }

  body {
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: 'Manrope', sans-serif;

  }
  body.fontLoaded {
    font-family: 'Manrope'
    
  }
  body .slate-highlight {
    background-color:#FBFF4E
  }

::selection{
  background: #E1E1FE !important;
  }

  body .flash-view{
    background-color:#f0f0f0;
  }

  #tixio-main-container {
    background-color: ${({ theme }) => Utils.formatColor(theme.colors.cardLightColor, '1')};
    min-height: 100%;
    min-width: 100%;
  }
  h1, h2, h3, h4, h5, p, small{
    color: #212429;
  }
  h1{
    font-size: 40px;
    font-weight: ${(props) => props.theme.weight.bold};
  }
  h2{
    font-weight: ${(props) => props.theme.weight.semiBold};
    font-size: 30px;
  }
  h3{
    font-size: 20px;
  }
  h4{
    font-size: 21px;
  }
  h5{
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 12px;
  }

  p{
    font-size: ${(props) => props.theme.fontSizes.md};
  }
  small{
    font-size: 12px;
  }
  p,
  label {
    font-family: 'Manrope', sans-serif;
    line-height: 1.5em;
  }
  input, select {
    font-family: inherit;
    font-size: inherit;
  }
  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    cursor: pointer;
    &:hover,
    &:focus {
      outline: 0;
    }
  }
  button {
    cursor: pointer;
    &:focus,
    &:active {
      outline: none;
    }
  }
  .tippy-box {
    outline: none;
    background-color:transparent !important
  }

  * {
    scrollbar-width: 12px;
    scrollbar-color: ${(props) => Utils.formatColor(props.theme.colors.primaryColor, '1')};
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color:transparent !important
  }

  *::-webkit-scrollbar-thumb {
    background-color:#adadade6;
    border-radius: 50px;
  }

  .thick-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .thick-scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color:transparent !important
  }

  .thick-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 50px;
  }
  .thick-scrollbar::-webkit-scrollbar-corner {
    background: transparent;
  }

  .chat_editor_active, .thread_editor_active{
    border: 1px solid rgb(75 78 252) !important;
    transition:all 0.1s linear;
  }

  .mantine-MultiSelect-input{
    padding-right:0px !important;
  }
  

  // different mention item style for chat
  #chat-input-wrapper #mention_item {
  font-family: 'Manrope';
  width:max-content;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 154%;
  letter-spacing: 0.004em;
  color: #4b4efc;
  cursor: pointer;

  display: inline-block;
  padding: 4px;

  background: #e1e1fe;
  border-radius: 6px;
  }
  
  #chat-input-wrapper #mention_item  a {
  color: #4b4efc;
  font-weight:500
  }

  .shake{
    animation: shake 0.25s ease-in;
  }

  .breathe{
    animation: breathe 1s ease-in-out infinite;
  }

  .forward_backward{
    animation: forward_backward 1s ease-in-out infinite;
  }

  @keyframes forward_backward {
    0% { transform: translateX(0); }
    50% { transform: translateX(5px); }
    100% { transform: translateX(0); }
  }

  @keyframes breathe {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }

  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
  }

  @keyframes shake_sm {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(.35deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-0.35deg); }
    100% { transform: rotate(0deg); }
  }

  .shake_sm{
    animation: shake_sm 0.5s infinite linear;
  }

  .reacted{
    animation: reactedAnimation 0.5s ease-in;
  }

  @keyframes reactedAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  .with-fade-in {
    animation: fade-in 300ms ease-in-out;
  }
`;

export const squareBoxScrollBar = css`
  scrollbar-color: ${(props) => Utils.formatColor(props.theme.colors.placeholderColor, '0.35')};
  *::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0px !important;
    background-color: ${(props) => Utils.formatColor(props.theme.colors.placeholderColor, '0.15')} !important;
    box-shadow: none !important;
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => Utils.formatColor(props.theme.colors.blueViolet, '0.9')} !important;
    border-radius: 0px !important;
  }
`;

export default GlobalStyle;
