import { FullPageLoading } from '@components/Loader';
import { GlobalErrorPage } from '@containers/AppSumo/components/Error';
import { ErrorBoundary } from '@sentry/react';
import { globalStore } from '@store/global-store.provider';
import { FC, Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

const DontAllowWhileLoggedIn: FC = ({ children }) => {
  //!todo check security
  const isLoggedIn = globalStore.getState().authenticated;

  if (isLoggedIn) {
    return (
      <Navigate
        replace
        to="/board"
      />
    );
  }
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate('/board');
  //   }
  // }, []);

  if (isLoggedIn) return null;

  return <>{children}</>;
};

type RouteLazyFactoryProps = {
  factory: () => Promise<{
    default: FC;
  }>;
  name: string;
  dontAllowWhileloggedIn?: boolean;
  fallback?: NonNullable<React.ReactNode>;
};

export const RouteLazyFactory = ({ factory, name, dontAllowWhileloggedIn, fallback }: RouteLazyFactoryProps) => {
  const Component = lazy(factory);

  const Wrapped = () => (
    <ErrorBoundary
      fallback={(e) => {
        return <GlobalErrorPage error={e.error} />;
      }}
      onError={(e) => {
        console.error(e);
      }}
    >
      {dontAllowWhileloggedIn ? (
        <DontAllowWhileLoggedIn>
          <Suspense fallback={fallback ?? <FullPageLoading />}>
            <Component />
          </Suspense>
        </DontAllowWhileLoggedIn>
      ) : (
        <Suspense fallback={fallback ?? <FullPageLoading />}>
          <Component />
        </Suspense>
      )}
    </ErrorBoundary>
  );

  Wrapped.displayName = name;

  return Wrapped;
};
