import { Maintainance } from '@maintainance/Maintainance';
import { fetchIsInMaintainance } from '@maintainance/maintainance.service';
import { Routes } from '@routes/Routes';
import * as Sentry from '@sentry/react';
import { GlobalStoreProvider } from '@store/global-store.provider';
import { MetaTitleProvider } from '@store/title-context/title-context';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@utils/reactQueryClient';
import { VendorAnalytics } from '@utils/vendorAnalytics';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import config from './config';
import GlobalStyle from './styles/global-styles';
import theme, { darkTheme, lightTheme } from './styles/theme';

console.log({ importMetaEnv: import.meta.env });

const themeMode = 'light';

const bootstrap = async () => {
  const isInMaintenance = await fetchIsInMaintainance();

  // replace console.* for disable log on production
  if (import.meta.env.PROD) {
    // console.log = () => {};
    // console.error = () => {};
    // console.debug = () => {};
    // console.info = () => {};
    VendorAnalytics.initAll();
  }

  if (!import.meta.env.DEV) {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.sentry.environment,
      tracesSampleRate: 0.2,
      normalizeDepth: 10,
    });

    console.log('sentry init');
  }

  // For development only(MOCK API)
  if (import.meta.env.REACT_APP_MOCK) {
    import('./mocks/browser')
      .then(({ worker }) => {
        worker.start();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // For development only
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault();
    console.log('vite:preloadError', event);
    window.location.reload(); // for example, refresh the page
  });

  if (isInMaintenance) {
    ReactDOM.render(<Maintainance />, document.getElementById('tixio-main-container'));
  } else {
    ReactDOM.render(
      <ThemeProvider theme={{ ...theme, colors: themeMode === 'light' ? lightTheme : darkTheme }}>
        <QueryClientProvider client={queryClient}>
          <GlobalStoreProvider>
            <MetaTitleProvider>
              {/*todo double nicemodal provider
                another one in authenticatedapp.tsx
             */}
              {/* <NiceModal.Provider> */}
              <Routes />
              <ToastContainer
                position="bottom-right"
                style={{ zIndex: 99999 }}
              />
              {/* </NiceModal.Provider> */}
            </MetaTitleProvider>
            {import.meta.env.DEV && <ReactQueryDevtools position="bottom" />}
          </GlobalStoreProvider>
        </QueryClientProvider>
        <GlobalStyle />
      </ThemeProvider>,
      document.getElementById('tixio-main-container')
    );
  }
};

bootstrap();
