export const Error404Graphic = () => (
  <svg width="432" height="394" viewBox="0 0 432 394" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5162_12039)">
      <path
        d="M419 393.56C422.351 393.566 425.568 392.25 427.955 389.899C430.342 387.548 431.705 384.35 431.75 381C432.28 346.36 435.07 295.26 369.82 281.12C317 269.72 348.33 175.88 206.14 182C104.06 186.35 131.14 272.38 51.8202 279.55C-0.999805 284.27 -1.44981 351.06 0.580194 381.66C0.791036 384.887 2.22203 387.913 4.58262 390.123C6.94321 392.333 10.0564 393.562 13.2902 393.56H419Z"
        fill="#D6EBE9"
      />
      <path
        opacity="0.55"
        d="M369.82 281.15C317 269.72 348.33 175.88 206.14 182C181.86 183 164.9 188.69 152.07 196.81C156.39 196.24 160.98 195.81 165.87 195.64C308.06 189.57 276.76 283.4 329.56 294.84C394.11 308.84 392.06 358.99 391.5 393.61H419C422.359 393.615 425.584 392.293 427.973 389.931C430.361 387.569 431.719 384.359 431.75 381C432.28 346.39 435.07 295.29 369.82 281.15Z"
        fill="#A2D7D2"
      />
      <path d="M145.97 0H143.73V163.8H145.97V0Z" fill="#5B69B3" />
      <path d="M306.94 0H304.7V188.96H306.94V0Z" fill="#5B69B3" />
      <path
        d="M306.73 178L139.73 158.51C138.034 158.312 136.316 158.45 134.673 158.916C133.03 159.383 131.496 160.168 130.156 161.228C128.817 162.287 127.7 163.6 126.869 165.092C126.037 166.583 125.507 168.224 125.31 169.92L112.9 276.63C112.702 278.326 112.84 280.045 113.307 281.687C113.773 283.33 114.558 284.865 115.618 286.204C116.677 287.543 117.99 288.66 119.482 289.492C120.973 290.323 122.614 290.853 124.31 291.05L291.31 310.54C293.006 310.738 294.725 310.6 296.367 310.134C298.01 309.667 299.545 308.882 300.884 307.822C302.223 306.763 303.34 305.45 304.172 303.958C305.003 302.467 305.533 300.826 305.73 299.13L318.19 192.37C318.575 188.945 317.584 185.507 315.435 182.812C313.286 180.118 310.155 178.387 306.73 178Z"
        fill="#D7F0F9"
      />
      <path
        d="M316.91 202.94L318.14 192.37C318.526 188.953 317.542 185.522 315.403 182.828C313.265 180.135 310.146 178.399 306.73 178L139.73 158.51C138.034 158.312 136.316 158.45 134.673 158.916C133.03 159.383 131.496 160.168 130.157 161.228C128.818 162.287 127.7 163.6 126.869 165.092C126.037 166.583 125.508 168.224 125.31 169.92L124.07 180.49L316.91 202.94Z"
        fill="#5B69B3"
      />
      <path
        d="M151.14 245.08L172.3 207.79C172.963 206.621 173.956 205.673 175.155 205.067C176.354 204.46 177.706 204.221 179.04 204.38C179.933 204.482 180.796 204.759 181.581 205.195C182.366 205.632 183.058 206.219 183.615 206.923C184.173 207.628 184.586 208.435 184.83 209.299C185.075 210.164 185.146 211.068 185.04 211.96L180.64 249.68H180.83C181.456 249.749 182.062 249.942 182.612 250.249C183.162 250.555 183.645 250.968 184.033 251.463C184.422 251.959 184.707 252.527 184.873 253.134C185.039 253.742 185.082 254.376 185 255C184.852 256.248 184.216 257.387 183.23 258.167C182.244 258.947 180.989 259.304 179.74 259.16H179.55L178.75 266.03C178.673 266.697 178.465 267.343 178.139 267.93C177.812 268.517 177.373 269.034 176.846 269.451C176.319 269.868 175.716 270.177 175.07 270.36C174.423 270.544 173.747 270.598 173.08 270.52C172.413 270.443 171.767 270.235 171.18 269.909C170.593 269.582 170.076 269.143 169.659 268.616C169.242 268.089 168.933 267.486 168.75 266.839C168.566 266.193 168.512 265.517 168.59 264.85L169.39 257.98L154.16 256.2C153.541 256.129 152.941 255.937 152.396 255.634C151.851 255.331 151.371 254.924 150.984 254.435C150.596 253.947 150.309 253.386 150.139 252.787C149.968 252.187 149.918 251.559 149.99 250.94L150.4 247.48C150.473 246.636 150.725 245.818 151.14 245.08ZM170.54 247.87L173.27 224.48C173.274 224.446 173.266 224.411 173.247 224.382C173.228 224.353 173.2 224.331 173.167 224.32C173.134 224.31 173.098 224.311 173.066 224.324C173.034 224.336 173.007 224.36 172.99 224.39L160.49 246.45C160.44 246.54 160.413 246.641 160.411 246.743C160.409 246.846 160.433 246.948 160.479 247.039C160.525 247.131 160.593 247.21 160.677 247.269C160.761 247.329 160.858 247.367 160.96 247.38L169.85 248.38C170.008 248.398 170.167 248.355 170.295 248.261C170.423 248.166 170.511 248.026 170.54 247.87Z"
        fill="#4CB6AC"
      />
      <path
        d="M244.53 256L265.69 218.71C266.356 217.543 267.349 216.598 268.548 215.992C269.746 215.386 271.096 215.145 272.43 215.3C274.23 215.511 275.874 216.427 276.999 217.848C278.124 219.27 278.638 221.079 278.43 222.88L274 260.58H274.19C274.807 260.652 275.405 260.845 275.947 261.148C276.49 261.451 276.968 261.857 277.353 262.345C277.739 262.833 278.024 263.391 278.193 263.989C278.363 264.587 278.413 265.213 278.34 265.83C278.269 266.448 278.077 267.046 277.775 267.59C277.473 268.134 277.066 268.613 276.578 268.999C276.091 269.386 275.531 269.672 274.933 269.842C274.334 270.012 273.708 270.062 273.09 269.99H272.9L272.1 276.86C271.94 278.207 271.252 279.435 270.187 280.275C269.122 281.115 267.767 281.498 266.42 281.34C265.753 281.263 265.108 281.055 264.521 280.728C263.935 280.402 263.419 279.962 263.003 279.435C262.586 278.909 262.278 278.305 262.096 277.659C261.914 277.012 261.861 276.337 261.94 275.67L262.74 268.8L247.53 267C246.911 266.928 246.313 266.735 245.769 266.431C245.225 266.128 244.746 265.721 244.36 265.232C243.974 264.744 243.687 264.184 243.518 263.585C243.348 262.985 243.298 262.359 243.37 261.74L243.77 258.28C243.874 257.478 244.132 256.704 244.53 256ZM263.94 258.79L266.67 235.4C266.674 235.366 266.666 235.331 266.647 235.302C266.628 235.273 266.6 235.251 266.567 235.24C266.534 235.23 266.498 235.231 266.466 235.244C266.434 235.256 266.407 235.28 266.39 235.31L253.89 257.37C253.838 257.459 253.809 257.561 253.805 257.664C253.802 257.768 253.825 257.871 253.872 257.963C253.918 258.056 253.988 258.135 254.073 258.194C254.158 258.253 254.257 258.289 254.36 258.3L263.24 259.3C263.403 259.32 263.567 259.276 263.698 259.177C263.828 259.078 263.915 258.932 263.94 258.77V258.79Z"
        fill="#4CB6AC"
      />
      <path
        d="M202.55 223.14C203.78 212.61 210.03 207.23 220.19 208.42C230.35 209.61 235.19 216.27 233.97 226.81L229.97 261.06C228.73 271.6 222.48 276.97 212.32 275.79C202.16 274.61 197.32 267.93 198.54 257.39L202.55 223.14ZM208.82 259.26C208.27 263.97 210.13 265.99 213.43 266.38C216.73 266.77 219 265.22 219.55 260.51L223.7 225C224.25 220.29 222.39 218.26 219.09 217.88C215.79 217.5 213.52 219.04 212.97 223.74L208.82 259.26Z"
        fill="#4CB6AC"
      />
      <path
        d="M143.81 173.21C143.732 173.898 143.452 174.548 143.004 175.077C142.557 175.606 141.962 175.99 141.296 176.181C140.63 176.372 139.922 176.36 139.263 176.149C138.603 175.937 138.021 175.534 137.591 174.991C137.16 174.449 136.9 173.79 136.844 173.1C136.788 172.409 136.938 171.717 137.275 171.112C137.613 170.507 138.122 170.016 138.739 169.7C139.356 169.385 140.052 169.259 140.74 169.34C141.66 169.448 142.499 169.916 143.075 170.641C143.65 171.366 143.914 172.29 143.81 173.21Z"
        fill="white"
      />
      <path
        d="M160.55 175.17C160.468 175.855 160.185 176.5 159.737 177.025C159.289 177.549 158.695 177.929 158.031 178.116C157.367 178.303 156.662 178.289 156.006 178.075C155.35 177.862 154.772 177.459 154.344 176.918C153.917 176.376 153.66 175.72 153.606 175.032C153.551 174.344 153.702 173.656 154.038 173.053C154.375 172.451 154.883 171.962 155.497 171.648C156.111 171.335 156.805 171.21 157.49 171.29C157.946 171.343 158.387 171.486 158.788 171.71C159.188 171.934 159.541 172.235 159.825 172.595C160.109 172.956 160.32 173.369 160.444 173.81C160.568 174.252 160.605 174.714 160.55 175.17Z"
        fill="#79C9E8"
      />
      <path
        d="M177.91 177.19C177.831 177.875 177.55 178.522 177.103 179.048C176.656 179.573 176.063 179.955 175.4 180.144C174.736 180.333 174.031 180.321 173.375 180.11C172.718 179.898 172.138 179.497 171.709 178.956C171.281 178.416 171.022 177.76 170.965 177.073C170.909 176.385 171.058 175.696 171.393 175.093C171.728 174.489 172.234 173.999 172.848 173.684C173.461 173.368 174.155 173.242 174.84 173.32C175.76 173.428 176.599 173.896 177.175 174.621C177.75 175.346 178.014 176.27 177.91 177.19Z"
        fill="#FFBF4D"
      />
      <path
        d="M110.75 298.79C109.365 305.435 108.735 312.214 108.87 319C109.21 328.15 108.12 388.31 108.12 388.31H115.47C115.47 388.31 125.93 341.2 126.32 337.48C126.71 333.76 132 296.56 132 296.56L110.75 298.79Z"
        fill="#00668E"
      />
      <path
        d="M115.64 296.05C115.64 296.05 117.33 308.9 120.2 318.05C123.07 327.2 137.2 388.33 137.2 388.33H144.55C144.55 388.33 143.2 341.22 142.69 337.5C142.18 333.78 138.46 296.57 138.46 296.57L115.64 296.05Z"
        fill="#0582C1"
      />
      <path
        d="M137.81 388.31V393.44H151.81C151.983 393.441 152.151 393.385 152.29 393.283C152.428 393.18 152.53 393.035 152.579 392.869C152.629 392.704 152.624 392.527 152.564 392.365C152.505 392.203 152.395 392.064 152.25 391.97C150.25 390.61 146.93 388.73 143.88 388.31H137.81Z"
        fill="#0582C1"
      />
      <path
        d="M108.49 388.31V393.44H122.49C122.664 393.443 122.833 393.389 122.974 393.287C123.114 393.185 123.217 393.04 123.268 392.874C123.319 392.708 123.314 392.53 123.255 392.367C123.196 392.204 123.086 392.065 122.94 391.97C120.89 390.61 117.61 388.73 114.57 388.31H108.49Z"
        fill="#0582C1"
      />
      <path
        d="M133.73 236.26C133.73 236.26 138.54 228.95 133.02 228.26C128.78 227.71 127.02 228.97 127.02 228.97C127.02 228.97 122.14 228.44 119.47 230.75C117.64 232.34 110.91 244.65 121.78 249.1C132.65 253.55 130.34 242.1 130.34 242.1L133.73 236.26Z"
        fill="#00668E"
      />
      <path
        d="M133 228.24C128.76 227.69 127 228.95 127 228.95C127 228.95 122.12 228.42 119.45 230.73C117.62 232.32 110.89 244.63 121.76 249.08C126.87 251.18 129.07 249.77 129.96 247.68C119.65 250.59 118.53 238.16 120.23 234.62C121.93 231.08 126.1 232.69 126.1 232.69C127.73 228.6 133.01 229.05 135.19 229.36C134.925 229.029 134.593 228.758 134.215 228.565C133.838 228.372 133.423 228.261 133 228.24Z"
        fill="#05556D"
      />
      <path
        d="M127 239.82C127 239.82 125.58 238.39 124.51 239.82C123.44 241.25 124.15 244.45 125.93 244.63C125.93 244.63 125.4 249.26 121.83 249.08V252.83H130.21V250C130.21 250 135.38 247.87 135.21 243.59C135.124 241.096 134.641 238.632 133.78 236.29C131.98 238.194 129.592 239.438 127 239.82Z"
        fill="#F98D3D"
      />
      <path
        d="M128.24 251.17C129.07 251.52 128.93 252.04 128.96 252.83H130.17V250C130.17 250 128.17 250.65 125.17 249C126.085 249.866 127.119 250.596 128.24 251.17Z"
        fill="#ED701B"
      />
      <path
        d="M130 233C130 233 125.66 231.27 125.15 232.57C124.82 233.42 125.66 234.15 126.82 234.89L125.82 235.05C125.767 235.057 125.718 235.08 125.678 235.115C125.639 235.15 125.61 235.196 125.596 235.247C125.582 235.298 125.584 235.352 125.6 235.403C125.617 235.453 125.648 235.497 125.69 235.53C126.545 235.893 127.474 236.044 128.4 235.97L130 233Z"
        fill="#F98D3D"
      />
      <path
        d="M161 243.87C152.73 237.74 130.12 232.87 130.12 232.87L128.26 236.27C128.26 236.27 148.78 244.27 147.42 245.41C146.63 246.11 136.6 250.7 133.2 252.25C132.468 252.58 131.673 252.744 130.87 252.73L120.48 252.67C117.944 252.547 115.414 253.013 113.088 254.034C110.763 255.054 108.706 256.6 107.08 258.55C101.81 264.62 92.8501 278.67 92.8501 278.67L107.95 300.87L109.62 303.32V303.5L111.11 303.65C115.96 304.1 131.51 305.33 139.74 303.58L139.97 303.53L139.58 267.11C147.533 260.904 154.798 253.864 161.25 246.11C161.389 245.95 161.494 245.763 161.557 245.561C161.62 245.359 161.641 245.146 161.617 244.935C161.594 244.724 161.527 244.521 161.42 244.338C161.314 244.154 161.171 243.995 161 243.87ZM110.3 293.54L108.82 290.32L104.13 280.07L111.92 271.35L110.52 290.48L110.3 293.54Z"
        fill="#FFBF4D"
      />
      <path d="M108.82 290.32L110.3 293.54L109.58 303.25L109.57 303.28L107.9 300.83L108.82 290.32Z" fill="#EFAA3A" />
      <g opacity="0.29">
        <path
          d="M136 265.76C134.6 266.76 135 275.76 134.61 281.41C134.03 290.84 133.47 299 133.88 304.27C135.825 304.165 137.76 303.921 139.67 303.54L139.9 303.49L139.51 267.07C147.463 260.864 154.727 253.824 161.18 246.07C161.318 245.91 161.421 245.724 161.483 245.523C161.545 245.322 161.565 245.11 161.542 244.9C161.518 244.691 161.452 244.489 161.347 244.306C161.241 244.124 161.099 243.965 160.93 243.84C153.292 251.877 144.948 259.213 136 265.76Z"
          fill="#F98D2B"
        />
      </g>
      <path
        d="M142.7 337.49C142.33 334.8 140.29 314.68 139.19 303.68L133.9 304.27C133.9 304.27 141.61 367.6 142.11 388.32H144.56C144.56 388.32 143.21 341.21 142.7 337.49Z"
        fill="#00668E"
      />
      <path
        d="M108.84 240.86L107.25 238C108.531 235.589 108.852 232.782 108.148 230.144C107.445 227.507 105.769 225.232 103.458 223.779C101.147 222.325 98.3717 221.8 95.6894 222.309C93.007 222.817 90.6165 224.322 88.9979 226.521C87.3792 228.719 86.6524 231.449 86.9635 234.161C87.2745 236.873 88.6003 239.368 90.6746 241.143C92.7489 242.918 95.418 243.842 98.1458 243.731C100.874 243.619 103.458 242.479 105.38 240.54L108.84 240.86Z"
        fill="#5B69B3"
      />
      <path
        d="M92.1104 232H92.5303V233.5C92.5303 233.96 92.6703 234.16 92.9903 234.16C93.3103 234.16 93.4204 234.03 93.6504 233.74V232H94.0704V234.46H93.7304V234.07C93.6426 234.203 93.5242 234.314 93.385 234.392C93.2459 234.471 93.0899 234.514 92.9304 234.52C92.4004 234.52 92.1703 234.18 92.1703 233.52L92.1104 232Z"
        fill="white"
      />
      <path
        d="M95.8204 234.65V235.48H95.4004V232H95.7504V232.28C95.9617 232.077 96.2382 231.957 96.5304 231.94C97.1704 231.94 97.5304 232.43 97.5304 233.19C97.5304 233.95 97.0304 234.52 96.4704 234.52C96.2176 234.502 95.9781 234.4 95.7904 234.23L95.8204 234.65ZM96.4204 234.16C96.8304 234.16 97.1204 233.79 97.1204 233.16C97.1204 232.53 96.9404 232.26 96.4804 232.26C96.2264 232.282 95.9903 232.4 95.8204 232.59V233.88C95.9828 234.039 96.1944 234.138 96.4204 234.16Z"
        fill="white"
      />
      <path
        d="M98.6504 233.89C98.8477 234.07 99.1034 234.173 99.3704 234.18C99.7004 234.18 99.8604 234.01 99.8604 233.8C99.8604 233.59 99.5704 233.44 99.3004 233.33C99.0304 233.22 98.5704 233.04 98.5704 232.63C98.5704 232.22 98.8804 231.93 99.4104 231.93C99.6893 231.928 99.9594 232.028 100.17 232.21L99.9804 232.47C99.8237 232.336 99.6265 232.258 99.4204 232.25C99.1104 232.25 98.9704 232.42 98.9704 232.61C98.9704 232.8 99.2304 232.92 99.5104 233.03C99.7904 233.14 100.26 233.31 100.26 233.77C100.26 234.23 99.9404 234.51 99.3604 234.51C99.0246 234.501 98.7011 234.382 98.4404 234.17L98.6504 233.89Z"
        fill="white"
      />
      <path
        d="M101.56 233.87C101.645 233.87 101.727 233.904 101.787 233.964C101.847 234.024 101.88 234.105 101.88 234.19C101.88 234.275 101.847 234.356 101.787 234.416C101.727 234.476 101.645 234.51 101.56 234.51C101.475 234.51 101.394 234.476 101.334 234.416C101.274 234.356 101.24 234.275 101.24 234.19C101.24 234.105 101.274 234.024 101.334 233.964C101.394 233.904 101.475 233.87 101.56 233.87Z"
        fill="white"
      />
      <path
        d="M103.32 233.87C103.405 233.87 103.486 233.904 103.546 233.964C103.606 234.024 103.64 234.105 103.64 234.19C103.64 234.275 103.606 234.356 103.546 234.416C103.486 234.476 103.405 234.51 103.32 234.51C103.235 234.51 103.154 234.476 103.094 234.416C103.034 234.356 103 234.275 103 234.19C103 234.105 103.034 234.024 103.094 233.964C103.154 233.904 103.235 233.87 103.32 233.87Z"
        fill="white"
      />
      <path
        d="M67.5004 370.49C67.5004 370.49 65.2404 368.38 66.7604 360.36C68.2804 352.34 72.1304 344.63 70.2404 335.92C68.3504 327.21 53.5204 305.27 53.4704 303.81C53.4204 302.35 48.8904 329.34 56.5604 340C64.2304 350.66 65.1604 354.33 64.8204 359.92C64.4804 365.51 64.8904 370.02 65.7404 371.32L67.5004 370.49Z"
        fill="#79C9E8"
      />
      <path
        d="M66.1 354.36C65.21 357.94 64.67 366.91 66.1 369.06C66.24 369.26 66.43 369.15 66.67 368.77C66.23 367.29 65.95 364.69 66.77 360.36C68.29 352.36 72.14 344.63 70.25 335.92C68.36 327.21 53.53 305.27 53.48 303.81C53.48 303.81 55.71 322.09 63.24 334.68C68.62 343.69 67 350.78 66.1 354.36Z"
        fill="#49B4D6"
      />
      <path
        d="M70.2104 369.94C70.2104 369.94 65.8504 359.06 73.2104 349.64C80.5704 340.22 92.4004 328.12 92.6404 323.33C92.6404 323.33 95.6404 346.08 86.0604 352.52C76.4804 358.96 69.7404 360 71.4004 369.9L70.2104 369.94Z"
        fill="#79C9E8"
      />
      <path
        d="M80.5801 348C69.8601 355.8 68.9901 364.79 70.6501 368.95C70.7061 369.104 70.7992 369.243 70.9213 369.353C71.0434 369.462 71.1906 369.541 71.3501 369.58C69.9101 360.02 76.6001 358.93 86.0901 352.58C95.5801 346.23 92.6701 323.39 92.6701 323.39C92.6701 323.39 91.3001 340.14 80.5801 348Z"
        fill="#49B4D6"
      />
      <path
        d="M63.0003 370.18C63.8539 366.24 64.0734 362.189 63.6503 358.18C62.9103 351.96 56.4103 348.46 50.3403 341.89C47.2203 338.51 46.3403 320.64 48.2303 318.98C48.2303 318.98 37.5103 331.98 39.1703 341.48C40.8303 350.98 58.3503 357.19 60.0003 361.78C60.9697 364.496 61.4735 367.356 61.4903 370.24L63.0003 370.18Z"
        fill="#79C9E8"
      />
      <path
        d="M44.6501 324.11C41.1101 333.7 44.7401 345.32 56.5101 352.54C65.1701 357.85 62.4101 368.9 62.4101 368.9L63.8301 364.54C63.9747 362.427 63.9279 360.305 63.6901 358.2C62.9501 351.98 56.4501 348.48 50.3801 341.91C47.3801 338.65 46.3801 321.91 48.0801 319.24C46.8552 320.804 45.7104 322.43 44.6501 324.11Z"
        fill="#49B4D6"
      />
      <path d="M48.0801 319.24L48.2701 319C48.1948 319.07 48.1307 319.151 48.0801 319.24Z" fill="#49B4D6" />
      <path
        d="M72.4502 369.46C72.4502 369.46 74.9602 360.14 81.1802 358.89C87.4002 357.64 94.5202 348.45 94.9102 346.65C94.9102 346.65 93.8602 364 72.4502 369.46Z"
        fill="#79C9E8"
      />
      <path
        d="M80.8201 362C77.0101 363.9 75.4301 366.46 74.8701 368.79C93.9301 362.66 94.8701 346.68 94.8701 346.68C94.6291 347.333 94.2921 347.946 93.8701 348.5C92.0701 351.75 87.5401 358.61 80.8201 362Z"
        fill="#49B4D6"
      />
      <path
        d="M85.8904 366H53.4004L57.2604 390.27C57.3944 391.167 57.8454 391.986 58.5316 392.578C59.2178 393.171 60.0937 393.498 61.0004 393.5H78.2904C79.1869 393.49 80.051 393.163 80.73 392.578C81.4089 391.992 81.8589 391.185 82.0004 390.3L85.8904 366Z"
        fill="#FFBF4D"
      />
      <path d="M85.2604 370.05L85.8904 366.03H53.4004L54.0404 370.05H85.2604Z" fill="#FF9F50" />
      <path
        d="M78.0004 366L74.1604 390.3C74.02 391.191 73.5661 392.003 72.8806 392.59C72.195 393.176 71.3227 393.499 70.4204 393.5H78.2904C79.1869 393.49 80.0511 393.163 80.73 392.578C81.4089 391.992 81.8589 391.185 82.0004 390.3L85.8904 366H78.0004Z"
        fill="#FF9F50"
      />
      <path d="M310.02 322.75H163.16V352.61H310.02V322.75Z" fill="#FFBF4D" />
      <g opacity="0.29">
        <path d="M310.02 322.75H163.16V330H310.02V322.75Z" fill="#F98D2B" />
      </g>
      <path d="M175.93 322.75H189.33L185.74 330H172.33L175.93 322.75Z" fill="#F77E2D" />
      <path d="M206.13 322.75H219.53L215.94 330H202.54L206.13 322.75Z" fill="#F77E2D" />
      <path d="M236.33 322.75H249.73L246.14 330H232.74L236.33 322.75Z" fill="#F77E2D" />
      <path d="M266.53 322.75H279.92L276.33 330H262.93L266.53 322.75Z" fill="#F77E2D" />
      <path d="M310.02 322.75V322.95L306.52 330H293.13L296.73 322.75H310.02Z" fill="#F77E2D" />
      <path d="M191.79 352.61H181.44V393.44H191.79V352.61Z" fill="#5B69B3" />
      <path d="M191.79 352.61H189.44V393.44H191.79V352.61Z" fill="#5B69B3" />
      <path d="M292.54 352.61H282.19V393.44H292.54V352.61Z" fill="#5B69B3" />
      <path d="M292.54 352.61H289.48V393.44H292.54V352.61Z" fill="#5B69B3" />
      <path d="M191.79 352.58H181.44V357.72H191.79V352.58Z" fill="#5B69B3" />
      <path d="M292.54 352.58H282.19V357.72H292.54V352.58Z" fill="#5B69B3" />
      <path d="M191.79 318.08H181.44V322.72H191.79V318.08Z" fill="#79C9E8" />
      <path d="M191.79 318.08H189.44V322.72H191.79V318.08Z" fill="#49B4D6" />
      <path d="M292.54 318.08H282.19V322.72H292.54V318.08Z" fill="#79C9E8" />
      <path d="M292.54 318.08H289.48V322.72H292.54V318.08Z" fill="#49B4D6" />
      <path d="M191.79 322.14H181.44V322.72H191.79V322.14Z" fill="#49B4D6" />
      <path d="M292.54 322.14H282.19V322.72H292.54V322.14Z" fill="#49B4D6" />
      <path d="M189.33 322.75L174.52 352.61H163.16V348.49L175.93 322.75H189.33Z" fill="#0582C1" />
      <path d="M206.13 322.75L191.32 352.61H204.72L219.53 322.75H206.13Z" fill="#0582C1" />
      <path d="M249.73 322.75L234.92 352.61H221.52L236.33 322.75H249.73Z" fill="#0582C1" />
      <path d="M279.92 322.75L265.12 352.61H251.72L266.53 322.75H279.92Z" fill="#0582C1" />
      <path d="M296.73 322.75L281.92 352.61H295.31L310.02 322.95L296.73 322.75Z" fill="#0582C1" />
      <path d="M189.33 322.75L185.74 330H172.33L175.93 322.75H189.33Z" fill="#00668E" />
      <path d="M219.53 322.75L215.94 330H202.54L206.13 322.75H219.53Z" fill="#00668E" />
      <path d="M249.73 322.75L246.14 330H232.74L236.33 322.75H249.73Z" fill="#00668E" />
      <path d="M279.92 322.75L276.33 330H262.93L266.53 322.75H279.92Z" fill="#00668E" />
      <path d="M310.02 322.95L306.52 330H293.13L296.73 322.75L310.02 322.95Z" fill="#00668E" />
      <path opacity="0.55" d="M307.49 335.67H167.25V350.61H307.49V335.67Z" fill="url(#paint0_linear_5162_12039)" />
      <path
        d="M187.808 320.725C192.839 319.906 196.254 315.164 195.436 310.132C194.617 305.101 189.875 301.686 184.844 302.504C179.812 303.323 176.397 308.065 177.215 313.097C178.034 318.128 182.776 321.543 187.808 320.725Z"
        fill="#FFBF4D"
      />
      <path
        d="M190.53 311.6C190.53 310.771 190.284 309.96 189.824 309.271C189.363 308.582 188.708 308.045 187.942 307.728C187.175 307.411 186.332 307.329 185.519 307.491C184.706 307.654 183.96 308.054 183.374 308.641C182.788 309.228 182.39 309.975 182.23 310.789C182.069 311.602 182.154 312.445 182.472 313.21C182.791 313.976 183.329 314.63 184.02 315.089C184.71 315.548 185.521 315.792 186.35 315.79C186.9 315.79 187.445 315.682 187.952 315.471C188.46 315.26 188.921 314.951 189.31 314.562C189.698 314.173 190.006 313.711 190.215 313.203C190.425 312.694 190.532 312.15 190.53 311.6Z"
        fill="#0582C1"
      />
      <path
        d="M288.768 320.734C293.799 319.915 297.214 315.173 296.396 310.141C295.577 305.11 290.835 301.695 285.804 302.513C280.772 303.332 277.357 308.074 278.175 313.105C278.994 318.137 283.736 321.552 288.768 320.734Z"
        fill="#FFBF4D"
      />
      <path
        d="M291.49 311.6C291.49 310.771 291.244 309.96 290.783 309.271C290.323 308.582 289.668 308.045 288.901 307.728C288.135 307.411 287.292 307.329 286.479 307.491C285.666 307.654 284.92 308.054 284.334 308.641C283.748 309.228 283.35 309.975 283.19 310.789C283.029 311.602 283.114 312.445 283.432 313.21C283.751 313.976 284.289 314.63 284.98 315.089C285.67 315.548 286.481 315.792 287.31 315.79C287.86 315.79 288.404 315.682 288.912 315.471C289.42 315.26 289.881 314.951 290.27 314.562C290.658 314.173 290.966 313.711 291.175 313.203C291.385 312.694 291.492 312.15 291.49 311.6Z"
        fill="#0582C1"
      />
      <path
        d="M365.66 390.27H318.89L326 372.93L328.88 365.93L329.67 364.02L330.97 360.89L331.23 360.26L334.11 353.26L334.64 351.99L339.74 339.61C339.943 339.113 340.289 338.688 340.734 338.389C341.179 338.09 341.704 337.93 342.24 337.93C342.777 337.93 343.301 338.09 343.746 338.389C344.191 338.688 344.537 339.113 344.74 339.61L349.84 351.99L350.36 353.26L353.24 360.26L353.51 360.89L354.8 364.02L355.59 365.93L358.47 372.93L365.66 390.27Z"
        fill="#FFBF4D"
      />
      <g opacity="0.29">
        <path d="M321.96 382.84L318.89 390.27H365.67L362.6 382.84H321.96Z" fill="#F98D2B" />
      </g>
      <path d="M368.72 385.83H315.15V393.4H368.72V385.83Z" fill="#5B69B3" />
      <path d="M368.72 385.83H357.15V393.4H368.72V385.83Z" fill="#5B69B3" />
      <g opacity="0.29">
        <path
          d="M363.82 385.83H357.21L351.89 372.94L349.01 365.94L348.22 364.02L346.93 360.9L346.67 360.26L343.79 353.26L343.26 351.99L339 341.56L339.81 339.56C340.013 339.063 340.359 338.638 340.804 338.339C341.249 338.04 341.774 337.88 342.31 337.88C342.846 337.88 343.371 338.04 343.816 338.339C344.261 338.638 344.607 339.063 344.81 339.56L349.91 351.94L350.43 353.21L353.31 360.21L353.58 360.85L354.87 363.97L355.66 365.89L358.54 372.89L363.82 385.83Z"
          fill="#F98D2B"
        />
      </g>
      <path d="M353.28 360.25H331.27L334.15 353.25H350.4L353.28 360.25Z" fill="#0582C1" />
      <path d="M358.51 372.92V372.93H326.04V372.92L328.92 365.92H355.63L358.51 372.92Z" fill="#0582C1" />
      <path d="M353.28 360.25H346.67L343.79 353.25H350.4L353.28 360.25Z" fill="#00668E" />
      <path d="M358.51 372.92V372.93H351.89V372.92L349.01 365.92H355.63L358.51 372.92Z" fill="#00668E" />
      <path
        d="M67.5203 220.21C69.2159 220.227 70.8601 219.629 72.1476 218.525C73.435 217.422 74.2781 215.888 74.5203 214.21C74.609 213.581 74.6525 212.946 74.6503 212.31C74.6503 208.597 73.1753 205.036 70.5498 202.411C67.9243 199.785 64.3633 198.31 60.6503 198.31C60.3303 198.31 60.0203 198.31 59.7103 198.31C59.3682 194.514 57.5775 190.995 54.7098 188.484C51.8422 185.973 48.1177 184.662 44.3094 184.824C40.5011 184.986 36.9015 186.609 34.2576 189.354C31.6136 192.1 30.1285 195.758 30.1103 199.57C30.1103 199.93 30.1103 200.29 30.1103 200.65C27.9336 199.439 25.4869 198.796 22.9961 198.78C20.5053 198.764 18.0505 199.375 15.8584 200.558C13.6662 201.741 11.8073 203.456 10.4531 205.547C9.09897 207.638 8.29324 210.036 8.11028 212.52C8.11524 212.58 8.11524 212.64 8.11028 212.7C8.05997 213.66 8.20594 214.62 8.53928 215.522C8.87261 216.423 9.38631 217.247 10.049 217.944C10.7116 218.64 11.5093 219.194 12.3933 219.572C13.2773 219.949 14.229 220.143 15.1903 220.14L67.5203 220.21Z"
        fill="#D6EBE9"
      />
      <path
        d="M400.65 231.17C411.65 231.17 412.3 214.56 401.27 214.02H400.37C398.452 214.017 396.545 214.318 394.72 214.91C393.979 210.432 391.655 206.37 388.171 203.461C384.687 200.553 380.274 198.993 375.736 199.064C371.198 199.136 366.837 200.834 363.446 203.851C360.055 206.867 357.86 211.001 357.26 215.5C353.55 213.737 349.339 213.331 345.36 214.35C335.71 216.83 337.73 231.19 347.7 231.19L400.65 231.17Z"
        fill="#D6EBE9"
      />
      <path
        d="M268.09 149.32C279.09 149.32 279.74 132.72 268.72 132.18H267.81C265.896 132.179 263.993 132.476 262.17 133.06C261.436 128.578 259.114 124.509 255.629 121.597C252.144 118.684 247.728 117.121 243.186 117.194C238.645 117.267 234.281 118.97 230.891 121.993C227.501 125.016 225.311 129.157 224.72 133.66C221.013 131.889 216.8 131.478 212.82 132.5C203.17 134.99 205.2 149.34 215.16 149.34L268.09 149.32Z"
        fill="#D6EBE9"
      />
      <path
        d="M92.4002 180.01C107.008 180.01 118.85 168.168 118.85 153.56C118.85 138.952 107.008 127.11 92.4002 127.11C77.7923 127.11 65.9502 138.952 65.9502 153.56C65.9502 168.168 77.7923 180.01 92.4002 180.01Z"
        fill="#FFD54E"
      />
      <path
        d="M92.6204 127.11C97.3806 130.235 101.006 134.812 102.957 140.161C104.909 145.51 105.083 151.347 103.453 156.803C101.824 162.259 98.4776 167.043 93.9119 170.446C89.3462 173.849 83.8046 175.688 78.1104 175.69H77.9004C81.3386 177.944 85.2549 179.365 89.3383 179.841C93.4218 180.317 97.56 179.835 101.424 178.432C105.289 177.03 108.773 174.746 111.601 171.762C114.429 168.777 116.522 165.175 117.714 161.241C118.906 157.306 119.165 153.148 118.47 149.096C117.775 145.044 116.145 141.21 113.709 137.898C111.273 134.586 108.099 131.888 104.438 130.017C100.778 128.146 96.7314 127.154 92.6204 127.12V127.11Z"
        fill="#FBC929"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5162_12039"
        x1="237.37"
        y1="361.34"
        x2="237.37"
        y2="337.29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.12" stopColor="white" stopOpacity="0.82" />
        <stop offset="0.3" stopColor="white" stopOpacity="0.58" />
        <stop offset="0.47" stopColor="white" stopOpacity="0.37" />
        <stop offset="0.64" stopColor="white" stopOpacity="0.21" />
        <stop offset="0.78" stopColor="white" stopOpacity="0.1" />
        <stop offset="0.91" stopColor="white" stopOpacity="0.02" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_5162_12039">
        <rect width="431.91" height="393.56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
