import { FC, createContext, useContext, useEffect, useState } from 'react';

type MetaTitleContextType = {
  setMetaTitle: (title: string | null) => void;
};

const prefix = 'Tixio';

const MetaTitleContext = createContext<MetaTitleContextType | null>(null);

export const MetaTitleProvider: FC = ({ children }) => {
  const [metaTitle, setMetaTitle] = useState<string | null>(null);

  useEffect(() => {
    document.title = metaTitle ? `${metaTitle} - ${prefix}` : prefix;
  }, [metaTitle]);

  return (
    <MetaTitleContext.Provider
      value={{
        setMetaTitle,
      }}
    >
      {children}
    </MetaTitleContext.Provider>
  );
};

export const useSetMetaTitle = (title: string) => {
  const context = useContext(MetaTitleContext);

  if (!context) {
    throw new Error('useSetTitle must be used within a TitleProvider');
  }

  useEffect(() => {
    context.setMetaTitle(title);
  }, [context, title]);
};
