import './splashScreenStyles.css';

type Props = {
  loaderColor?: string;
  trackColor?: string;
  height?: string;
  width?: string;
};
export const LinearProgressLoader = ({
  width = '100%',
  height = '4px',
  loaderColor = 'blue',
  trackColor = 'lightgray',
}: Props) => {
  return (
    <div
      className="progress-bar"
      style={{
        width,
        height,
        backgroundColor: trackColor,
      }}
    >
      <div
        className="progress"
        style={{
          backgroundColor: loaderColor,
        }}
      />
    </div>
  );
};
