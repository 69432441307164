import { FolderViewType } from '@containers/Board/components/FolderView/FolderViewIndex';
import { globalStoreCookie } from '@store/global-store-cookie.service';
import { sidebarAccordionType } from '../store/types';

const selectedWorkspace = globalStoreCookie.getCookies()?.selectedCompanyID;
export const TASK_MODULE_ACCORDIONS = `TASK_MODULE_ACCORDIONS_${selectedWorkspace}`;
export const PROJECT_TAB_KEY = 'PROJECT_TAB_KEY';
export const TASK_SUBTASK_VIEW_OPEN = 'TASK_SUBTASK_VIEW_OPEN';
export const MAINTENANCE_BYPASS = '__MAINTENANCE_BYPASS';
export const ACTIVE_FOLDER_VIEW = 'ACTIVE_FOLDER_VIEW';

const defaultTaskModuleAccordions: sidebarAccordionType = {
  favorite: true,
  privates: true,
  shared: true,
};

const defaultProjectTab = 'list';

const defaultFolderView = 'list';

const defaultTaskSubTaskViewOpen = false;

const defaultValues = {
  [TASK_MODULE_ACCORDIONS]: defaultTaskModuleAccordions,
  [PROJECT_TAB_KEY]: defaultProjectTab,
  [TASK_SUBTASK_VIEW_OPEN]: defaultTaskSubTaskViewOpen,
  [MAINTENANCE_BYPASS]: '',
  [ACTIVE_FOLDER_VIEW]: defaultFolderView,
};

export const getItemFromLocalStorage = <T>(key: string): T => {
  const itemValue = localStorage.getItem(key);

  return itemValue ? JSON.parse(itemValue) : defaultValues[key];
};

export const setItemToLocalStorage = <T>(key: string, value: T) => {
  const currentValue = localStorage.getItem(key);
  const parsedValues = currentValue ? JSON.parse(currentValue) : {};

  localStorage.setItem(key, JSON.stringify({ ...parsedValues, ...value }));
};

export const getLastViewedTab = (projectID: string) => {
  const allProjectTabs = getItemFromLocalStorage<Record<string, string>>(PROJECT_TAB_KEY);

  return allProjectTabs[projectID] || defaultProjectTab;
};

export const getTaskModuleAccordionsFromLocalStorage = () => {
  return getItemFromLocalStorage<sidebarAccordionType>(TASK_MODULE_ACCORDIONS);
};

export const getSubtaskViewExpandStatus = (parentTaskID: string) => {
  const allSubtaskExpandState = getItemFromLocalStorage<Record<string, boolean>>(TASK_SUBTASK_VIEW_OPEN);

  return allSubtaskExpandState[parentTaskID];
};

export const getFolderViewActiveTab = (folderID: string): FolderViewType => {
  const allFolderViews = getItemFromLocalStorage<Record<string, FolderViewType>>(ACTIVE_FOLDER_VIEW);

  return allFolderViews[folderID] || defaultFolderView;
};
