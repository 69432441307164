import { SigninEntity } from '@containers/OnBoarding/services/types/signin.entity';
import { createAuthAxios } from '@hooks/axios.hook';
import { BaseResponse, makeAuthMutation, makeAuthQuery, processResponse } from '@services/entites/base-response.entity';
import { globalStoreCookie } from '@store/global-store-cookie.service';
import { useMutation } from '@tanstack/react-query';
import { UpdateEmailDTO, UpdateProfileDTO, VerifyEmailDTO } from './dto/account.dto';
import { TokensEntity } from './dto/workpsace.dto';
import { DefaultAvatarEntity, UserEntity } from './entity/account.entity';

export const PROFILE_DEFAULT_AVATAR = 'PROFILE_DEFAULT_AVATAR';
export const GET_OLD_PASSWORD_REQUIRED = 'GET_OLD_PASSWORD_REQUIRED';

const useGetMeWithToken = () => {
  return useMutation({
    mutationFn: async (tokens: TokensEntity) => {
      const axios = createAuthAxios({
        getAccessToken: () => tokens.accessToken,
        getRefreshToken: () => tokens.refreshToken,
        setAccessToken: (newAccessToken: string) => {
          globalStoreCookie.setGlobalStoreCookie((p) => {
            if (!p) {
              return null;
            }
            return {
              ...p,
              accessToken: newAccessToken,
            };
          });
        },
        onLogout: () => {
          globalStoreCookie.setGlobalStoreCookie(() => null);
        },
        baseURL: import.meta.env.REACT_APP_API_URL,
      });

      return processResponse(() => axios.get<BaseResponse<SigninEntity>>('/auth/me'));
    },
  });
};

const useGetDefaultAvatars = makeAuthQuery(
  (http) => http.get<DefaultAvatarEntity[]>('user/default-avatars'),
  [PROFILE_DEFAULT_AVATAR]
);
const useGetOldPasswordRequired = makeAuthQuery(
  (http) => http.get<boolean | null>('user/is-old-password-required'),
  [GET_OLD_PASSWORD_REQUIRED]
);

const useUploadAvatar = makeAuthMutation((http, file: File) => {
  const formData = new FormData();
  formData.set('avatar', file);
  return http.post<UserEntity>(`/user/update-avatar`, formData);
});

const useUploadDefaultAvatar = makeAuthMutation((http, id: string) =>
  http.patch<UserEntity>(`user/default-avatars/${id}`)
);

const useVerifyEmail = makeAuthMutation((http, dto: VerifyEmailDTO) =>
  http.post<string>(`/user/request-email-change`, dto)
);

const useUpdateEmail = makeAuthMutation((http, dto: UpdateEmailDTO) => http.patch<any>(`/user/change-email`, dto));

const useUpdateProfile = makeAuthMutation((http, dto: UpdateProfileDTO) => http.patch<UserEntity>(`/user/update`, dto));
const useUpdatePassword = makeAuthMutation((http, dto: string) =>
  http.patch<string>(`/user/change-password`, { password: dto })
);

export const accountService = {
  useGetDefaultAvatars,
  useUploadAvatar,
  useUploadDefaultAvatar,
  useGetOldPasswordRequired,
  useVerifyEmail,
  useUpdateEmail,
  useUpdateProfile,
  useUpdatePassword,
  useGetMeWithToken,
};
