export const TixioLogoSvg = () => (
  <svg width="84" height="24" viewBox="0 0 84 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_416_7074)">
      <path
        d="M2.99834 7.73976H2.24204C1.64764 7.73976 1.07759 7.49483 0.657282 7.05884C0.236978 6.62286 0.000854492 6.03153 0.000854492 5.41496C0.000854492 4.79838 0.236978 4.20706 0.657282 3.77107C1.07759 3.33508 1.64764 3.09015 2.24204 3.09015H2.99834C3.59274 3.09015 4.1628 3.33508 4.5831 3.77107C5.0034 4.20706 5.23953 4.79838 5.23953 5.41496C5.23953 6.03153 5.0034 6.62286 4.5831 7.05884C4.1628 7.49483 3.59274 7.73976 2.99834 7.73976Z"
        fill="#AED580"
      />
      <path
        d="M19.3783 7.73976H10.0123C9.71798 7.73976 9.42655 7.67963 9.15464 7.5628C8.88272 7.44597 8.63566 7.27472 8.42755 7.05884C8.21943 6.84297 8.05435 6.58668 7.94172 6.30462C7.82909 6.02256 7.77112 5.72025 7.77112 5.41496C7.77112 5.10966 7.82909 4.80735 7.94172 4.52529C8.05435 4.24323 8.21943 3.98695 8.42755 3.77107C8.63566 3.55519 8.88272 3.38395 9.15464 3.26711C9.42655 3.15028 9.71798 3.09015 10.0123 3.09015H19.3783C19.6726 3.09015 19.9641 3.15028 20.236 3.26711C20.5079 3.38395 20.7549 3.55519 20.9631 3.77107C21.1712 3.98695 21.3363 4.24323 21.4489 4.52529C21.5615 4.80735 21.6195 5.10966 21.6195 5.41496C21.6195 5.72025 21.5615 6.02256 21.4489 6.30462C21.3363 6.58668 21.1712 6.84297 20.9631 7.05884C20.7549 7.27472 20.5079 7.44597 20.236 7.5628C19.9641 7.67963 19.6726 7.73976 19.3783 7.73976Z"
        fill="#70B2E2"
      />
      <path
        d="M19.3772 23.917H18.6228C18.0284 23.917 17.4583 23.6721 17.038 23.2361C16.6177 22.8001 16.3816 22.2088 16.3816 21.5922C16.3816 20.9757 16.6177 20.3843 17.038 19.9483C17.4583 19.5124 18.0284 19.2674 18.6228 19.2674H19.3772C19.9716 19.2674 20.5417 19.5124 20.962 19.9483C21.3823 20.3843 21.6184 20.9757 21.6184 21.5922C21.6184 22.2088 21.3823 22.8001 20.962 23.2361C20.5417 23.6721 19.9716 23.917 19.3772 23.917Z"
        fill="#4CB6AC"
      />
      <path
        d="M11.608 23.917H2.24204C1.64764 23.917 1.07759 23.6721 0.657282 23.2361C0.236979 22.8001 0.000854492 22.2088 0.000854492 21.5922C0.000854492 20.9757 0.236979 20.3843 0.657282 19.9483C1.07759 19.5124 1.64764 19.2674 2.24204 19.2674H11.608C12.2024 19.2674 12.7725 19.5124 13.1928 19.9483C13.6131 20.3843 13.8492 20.9757 13.8492 21.5922C13.8492 22.2088 13.6131 22.8001 13.1928 23.2361C12.7725 23.6721 12.2024 23.917 11.608 23.917Z"
        fill="#FFD54E"
      />
      <path
        d="M7.32823 15.8284H2.24118C1.64679 15.8284 1.07673 15.5835 0.656428 15.1475C0.236124 14.7115 0 14.1202 0 13.5036C0 12.887 0.236124 12.2957 0.656428 11.8597C1.07673 11.4237 1.64679 11.1788 2.24118 11.1788H7.32823C7.92263 11.1788 8.49268 11.4237 8.91299 11.8597C9.33329 12.2957 9.56941 12.887 9.56941 13.5036C9.56941 14.1202 9.33329 14.7115 8.91299 15.1475C8.49268 15.5835 7.92263 15.8284 7.32823 15.8284Z"
        fill="#F58967"
      />
      <path
        d="M19.3779 15.8284H14.339C13.7446 15.8284 13.1745 15.5835 12.7542 15.1475C12.3339 14.7115 12.0978 14.1202 12.0978 13.5036C12.0978 12.887 12.3339 12.2957 12.7542 11.8597C13.1745 11.4237 13.7446 11.1788 14.339 11.1788H19.3779C19.6722 11.1788 19.9637 11.2389 20.2356 11.3558C20.5075 11.4726 20.7546 11.6438 20.9627 11.8597C21.1708 12.0756 21.3359 12.3319 21.4485 12.6139C21.5611 12.896 21.6191 13.1983 21.6191 13.5036C21.6191 13.8089 21.5611 14.1112 21.4485 14.3933C21.3359 14.6753 21.1708 14.9316 20.9627 15.1475C20.7546 15.3634 20.5075 15.5346 20.2356 15.6514C19.9637 15.7683 19.6722 15.8284 19.3779 15.8284Z"
        fill="#637BF8"
      />
      <path
        d="M36.8911 23.3328C35.7135 23.7618 34.4759 23.9873 33.2279 24.0004C30.2082 24.0004 28.7899 22.2337 28.7899 18.9997V10.134H27.4086C27.343 10.1436 27.2762 10.1372 27.2135 10.1155C27.1507 10.0937 27.0937 10.0571 27.0469 10.0086C27.0001 9.96001 26.9648 9.90084 26.9438 9.83573C26.9228 9.77062 26.9167 9.70133 26.9259 9.63335V7.96647L28.7899 7.19921V5.10074C28.7952 4.94349 28.8433 4.79102 28.9288 4.66099C29.0142 4.53097 29.1334 4.42871 29.2725 4.36609L31.8151 3.06941C32.1683 2.86801 32.4253 3.06941 32.4253 3.50292V6.99972H36.0238C36.0893 6.99013 36.1561 6.99647 36.2189 7.01824C36.2817 7.04001 36.3387 7.07661 36.3855 7.12516C36.4323 7.1737 36.4676 7.23287 36.4886 7.29798C36.5096 7.3631 36.5157 7.43238 36.5064 7.50036V9.63335C36.5157 9.70133 36.5096 9.77062 36.4886 9.83573C36.4676 9.90084 36.4323 9.96001 36.3855 10.0086C36.3387 10.0571 36.2817 10.0937 36.2189 10.1155C36.1561 10.1372 36.0893 10.1436 36.0238 10.134H32.4253V18.1346C32.4253 20.1334 33.0356 20.8335 34.4169 20.8009C35.1364 20.7766 35.8496 20.6534 36.5379 20.4345C36.8263 20.3328 36.9558 20.6014 37.0205 20.9677L37.3423 22.8341C37.3737 23.1007 37.1167 23.2331 36.8911 23.3328Z"
        fill="#111827"
      />
      <path
        d="M38.9467 2.20013C38.9467 0.732737 39.9748 0 41.2359 0C42.5211 0 43.5178 0.732737 43.5178 2.20013C43.5178 3.79987 42.5211 4.40026 41.2359 4.40026C39.9748 4.40026 38.9467 3.79987 38.9467 2.20013ZM39.4293 7.5V23.1675C39.42 23.2354 39.426 23.3047 39.447 23.3697C39.468 23.4348 39.5033 23.4939 39.5502 23.5423C39.597 23.5907 39.6541 23.6271 39.7169 23.6486C39.7797 23.6701 39.8465 23.6762 39.9119 23.6662H42.5784C42.6438 23.6762 42.7106 23.6701 42.7734 23.6486C42.8362 23.6271 42.8933 23.5907 42.9401 23.5423C42.987 23.4939 43.0223 23.4348 43.0433 23.3697C43.0643 23.3047 43.0704 23.2354 43.061 23.1675V7.5C43.0703 7.43202 43.0642 7.36274 43.0432 7.29762C43.0222 7.23251 42.9869 7.17334 42.9401 7.1248C42.8933 7.07625 42.8363 7.03965 42.7735 7.01788C42.7107 6.99611 42.644 6.98977 42.5784 6.99936H39.9119C39.8464 6.98977 39.7796 6.99611 39.7168 7.01788C39.6541 7.03965 39.597 7.07625 39.5502 7.1248C39.5034 7.17334 39.4681 7.23251 39.4471 7.29762C39.4262 7.36274 39.42 7.43202 39.4293 7.5Z"
        fill="#111827"
      />
      <path
        d="M59.0314 23.6662H55.9803C55.8631 23.6742 55.7461 23.647 55.6435 23.5878C55.5408 23.5286 55.4568 23.4399 55.4015 23.3324L52.2191 18.0997L49.1347 23.3324C49.0832 23.4356 49.0048 23.5217 48.9083 23.5808C48.8118 23.6398 48.7012 23.6694 48.5892 23.6662H45.5029C45.1183 23.6662 44.9889 23.3995 45.2145 23.0658L50.1628 15.0997L45.3421 7.59966C45.1571 7.2659 45.2792 6.99928 45.6971 6.99928H48.7168C48.8377 6.99503 48.9576 7.02358 49.0646 7.08212C49.1716 7.14065 49.2621 7.22714 49.327 7.33304L52.3485 12.4334L55.2406 7.33304C55.2959 7.22552 55.3799 7.13684 55.4826 7.07764C55.5852 7.01843 55.7022 6.99122 55.8194 6.99928H58.8724C59.257 6.99928 59.3865 7.2659 59.1923 7.59966L54.366 15.2992L59.3587 23.0658C59.5436 23.4015 59.4179 23.6662 59.0314 23.6662Z"
        fill="#111827"
      />
      <path
        d="M61.0231 2.20013C61.0231 0.732737 62.0531 0 63.3049 0C64.5993 0 65.5868 0.732737 65.5868 2.20013C65.5868 3.79987 64.592 4.40026 63.3049 4.40026C62.0531 4.40026 61.0231 3.79987 61.0231 2.20013ZM61.5039 7.5V23.1675C61.4945 23.2354 61.5006 23.3047 61.5216 23.3697C61.5426 23.4348 61.5779 23.4939 61.6248 23.5423C61.6716 23.5907 61.7287 23.6271 61.7915 23.6486C61.8543 23.6701 61.921 23.6762 61.9865 23.6662H64.653C64.7184 23.6762 64.7852 23.6701 64.848 23.6486C64.9108 23.6271 64.9679 23.5907 65.0147 23.5423C65.0616 23.4939 65.0969 23.4348 65.1179 23.3697C65.1389 23.3047 65.1449 23.2354 65.1356 23.1675V7.5C65.1448 7.43202 65.1387 7.36274 65.1178 7.29762C65.0968 7.23251 65.0615 7.17334 65.0147 7.1248C64.9679 7.07625 64.9108 7.03965 64.8481 7.01788C64.7853 6.99611 64.7185 6.98977 64.653 6.99936H61.9883C61.9228 6.98977 61.856 6.99611 61.7932 7.01788C61.7305 7.03965 61.6734 7.07625 61.6266 7.1248C61.5798 7.17334 61.5445 7.23251 61.5236 7.29762C61.5026 7.36274 61.4965 7.43202 61.5057 7.5H61.5039Z"
        fill="#111827"
      />
      <path
        d="M67.6785 15.3342C67.6785 9.03306 71.1808 6.66797 75.8407 6.66797C80.5005 6.66797 84.0028 9.23447 84.0028 15.3342C84.0028 20.9678 80.5005 24.0005 75.8407 24.0005C71.1808 24.0005 67.6785 20.9678 67.6785 15.3342ZM80.3064 15.3342C80.3064 11.2677 78.5071 9.96337 75.8407 9.96337C73.1742 9.96337 71.3768 11.2677 71.3768 15.3342C71.3768 19.3623 73.176 20.7051 75.8444 20.7051C78.5127 20.7051 80.3027 19.3739 80.3027 15.3342H80.3064Z"
        fill="#111827"
      />
    </g>
    <defs>
      <clipPath id="clip0_416_7074">
        <rect width="84" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
