import styled from 'styled-components';
import Utils from '../../utils';

export const ErrorContentWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 30px 0;
  > div {
    width: 50%;
  }
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 80px;
`;
export const ErrorContentDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.div`
  width: 155px;
  margin: 0 0 40px 0;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const ErrorTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: ${(props) => Utils.formatColor(props.theme.colors.textColor, '1')};
  line-height: 38px;
`;
export const ErrorMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => Utils.formatColor(props.theme.colors.textColor, '1')};
  margin: 20px 0 40px 0;
`;

export const ErrorButton = styled.button`
  padding: 12px 30px;
  background-color: ${(props) => Utils.formatColor(props.theme.colors.primaryColor, '1')};
  color: ${(props) => Utils.formatColor(props.theme.colors.cardColor, '1')};
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  svg,
  svg path {
    fill: ${(props) => Utils.formatColor(props.theme.colors.cardColor, '1')};
  }
`;
export const ErrorGraphic = styled.div`
  padding: 0 30px;
  svg {
    width: 100%;
    height: auto;
  }
`;
