import { Progress, Stack, Text } from '@getonnet/tixio-ui-core';
import styled from 'styled-components';
import { ReactComponent as Loading } from '../../assets/images/tixio_loading.svg';
import { LinearProgressLoader } from './';
import './splashScreenStyles.css';

const SplashScreenWrapper = styled.div`
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 9999999;

  .logo {
    display: block;
    width: 100px;
    height: 100px;
  }
`;

type Props = {
  showProgress?: boolean;
  hint?: string;
  progress?: number;
};
export const FullPageLoading = ({ hint, showProgress = true, progress }: Props) => {
  console.trace('full page loading called');
  return (
    <SplashScreenWrapper>
      <Stack
        justify="center"
        align="center"
      >
        <Stack style={{ width: 'max-content' }}>
          <Loading className="logo" />

          {showProgress && progress !== undefined && (
            <Progress
              value={progress}
              striped
              animate
            />
          )}

          {showProgress && progress === undefined && (
            <LinearProgressLoader
              height="8px"
              loaderColor="#4B4EFC"
              trackColor="#f0f4f8"
            />
          )}
        </Stack>
        {hint && <Text size="sm">{hint}</Text>}
      </Stack>
    </SplashScreenWrapper>
  );
};
