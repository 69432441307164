export const lightTheme = {
  titleColor: '50, 51, 56',
  titleColor2: '56, 63, 69',
  subTitleColor: '103, 104, 121',
  placeholderColor: '144, 144, 144',
  textColor: '51, 51, 51',
  cardColor: '255, 255, 255',
  primaryLightColor: '244, 246, 255',
  cardLightColor: '251, 252, 255',
  infoBgColor: '246, 246, 246',
  lightBorderColor: '235, 235, 235',
  primaryColor: '123, 134, 194',
  warnColor: '222, 51, 14',
  warnBgColor: '97, 26, 20',
  leftBarBgColor: '62,70,87',
  darkShadowColor: '211, 211, 211',
  lightShadowColor: '235, 235, 235',
  blackShadowColor: '0, 0, 0',
  deepBlueShadow: '34, 44, 60',
  successColor: '0, 152, 97, 1',
  cucumberColor: '139, 173, 83',
  blueViolet: '91, 105, 179',
  commentHighlighter: '252, 217, 83',
  commentUnderline: '255, 200, 0',
  toolTipDarkColor: '62, 70, 87',
  dividerColor: '229, 229, 229',
  avatarBorderColor: '0, 99, 247',
  lightBlueColor: '248, 249, 252',
  silverColor: '194, 194, 194',
  athensGrayColor: '241, 242, 244',
  whiteLilacColor: '248, 249, 252',
  midNightColor: '0, 36, 65',
  aluminumColor: '172, 177, 185',
  searchHighlighter: '242, 199, 68',
  gullGrayColor: '153, 170, 181',
  ceruleanColor: '14, 135, 204',
  globalSearchHoverColor: '230, 233, 236',
  searchWidgetBorderColor: '99, 123, 248',
  analyticsContentBgColor: '249, 249, 251',
  lightSilverColor: '196, 196, 196',
  primaryShadeColor: '243, 244, 246',
  workspaceBorderColor: '231, 234, 255',
  secondaryColor: '0, 99, 247',
};

export const darkTheme = {
  titleColor: '251, 252, 255',
  titleColor2: '56, 63, 69',
  subTitleColor: '246, 246, 246',
  placeholderColor: '144,144,144',
  textColor: '251, 252, 255',
  cardColor: '51, 51, 51',
  primaryLightColor: '43, 52, 68',
  cardLightColor: '50, 51, 56',
  infoBgColor: '103, 104, 121',
  lightBorderColor: '235, 235, 235',
  primaryColor: '123, 134, 194',
  warnColor: '222, 51, 14',
  warnBgColor: '97, 26, 20',
  leftBarBgColor: '62,70,87',
  darkShadowColor: '211, 211, 211',
  lightShadowColor: '235, 235, 235',
  blackShadowColor: '0, 0, 0',
  deepBlueShadow: '34, 44, 60',
  successColor: '0, 152, 97, 1',
  cucumberColor: '139, 173, 83',
  blueViolet: '91, 105, 179',
  commentHighlighter: '252, 217, 83',
  commentUnderline: '255, 200, 0',
  toolTipDarkColor: '62, 70, 87',
  dividerColor: '229, 229, 229',
  avatarBorderColor: '0, 99, 247',
  lightBlueColor: '248, 249, 252',
  silverColor: '194, 194, 194',
  athensGrayColor: '241, 242, 244',
  whiteLilacColor: '248, 249, 252',
  midNightColor: '0, 36, 65',
  aluminumColor: '172, 177, 185',
  searchHighlighter: '242, 199, 68',
  gullGrayColor: '153, 170, 181',
  ceruleanColor: '14, 135, 204',
  globalSearchHoverColor: '230, 233, 236',
  searchWidgetBorderColor: '99, 123, 248',
  analyticsContentBgColor: '249, 249, 251',
  lightSilverColor: '196, 196, 196',
  primaryShadeColor: '243, 244, 246',
  workspaceBorderColor: '231, 234 ,255',
  secondaryColor: '0, 99, 247',
};

const theme = {
  fontSizes: {
    xxl: '26px',
    xl: '24px',
    xlg: '20px',
    lg: '18px',
    md: '16px',
    sm: '14px',
    xSm: '12px',
    xxSm: '10px',
  },
  shape: {
    xlBorderRadius: '20px',
    lgBorderRadius: '10px',
    mdBorderRadius: '8px',
    smBorderRadius: '6px',
    xSmBorderRadius: '4px',
  },
  weight: {
    light: 300,
    regular: 400,
    semiBold: 500,
    xSmBold: 600,
    bold: 700,
    extraBold: 800,
  },
  boxWidth: '525px',
};

export default theme;
