import { default as Cookies } from 'js-cookie';

export type OldCookies = 'ACCESS_TOKEN' | 'REFRESH_TOKEN' | 'WORKSPACE_ID' | 'SUBDOMAIN';

type CookieStorage = {
  selectedCompanyID: string | null;
  accessToken: string;
  refreshToken: string;
};

export const setOldCookie = (name: OldCookies, value: string, expiresIn?: number) => {
  Cookies.set(name, value, { expires: expiresIn, domain: import.meta.env.REACT_APP_BASE_DOMAIN });
};

export const removeOldCookie = (name: OldCookies) => {
  return Cookies.remove(name, { domain: import.meta.env.REACT_APP_BASE_DOMAIN });
};

class GlobalStoreCookies {
  getCookies() {
    const cookies = Cookies.getJSON('DEFAULT_DATA_TIXIO');

    return cookies as CookieStorage | null;
  }

  setGlobalStoreCookie(updater: (prev: CookieStorage | null) => CookieStorage | null) {
    const current = this.getCookies();
    const data = updater(current);

    if (!data) {
      Cookies.remove('DEFAULT_DATA_TIXIO', {
        secure: true,
        expires: 365,
        domain: import.meta.env.REACT_APP_BASE_DOMAIN,
        sameSite: 'strict',
      });

      this.clearAllOldCookies();
      return;
    }

    Cookies.set('DEFAULT_DATA_TIXIO', JSON.stringify(data), {
      secure: true,
      expires: 365,
      domain: import.meta.env.REACT_APP_BASE_DOMAIN,
      sameSite: 'strict',
    });

    this.setOldCookies(data);
  }

  private clearAllOldCookies() {
    removeOldCookie('ACCESS_TOKEN');
    removeOldCookie('REFRESH_TOKEN');
    removeOldCookie('WORKSPACE_ID');
    removeOldCookie('SUBDOMAIN');
  }

  private setOldCookies(data: CookieStorage) {
    setOldCookie('ACCESS_TOKEN', data.accessToken, 365);
    setOldCookie('REFRESH_TOKEN', data.refreshToken, 365);
    setOldCookie('WORKSPACE_ID', data.selectedCompanyID || '', 365);
  }
}

export const globalStoreCookie = new GlobalStoreCookies();
