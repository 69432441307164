import { Error404Graphic } from '@components/Icons/Error404Graphic';
import { TixioLogoSvg } from '@components/Icons/TixioLogoSvg';
import { Button, Text } from '@getonnet/tixio-ui-core';
import { ErrorContent, ErrorContentDiv, ErrorContentWrapper, ErrorGraphic, Logo } from './ErrorPageStyles';
import { ErrorPageProps } from './Types';

export const ErrorPage = ({
  title,
  buttonHandler,
  buttonText,
  image,
  message,
  hideButton = false,
  stack,
}: ErrorPageProps) => {
  return (
    <ErrorContentWrapper>
      <ErrorContent>
        <ErrorContentDiv>
          <Logo>
            <TixioLogoSvg />
          </Logo>
        </ErrorContentDiv>
        <ErrorContentDiv>
          <Text
            weight={900}
            size="xl"
            sx={{ fontSize: '32px' }}
            mb={5}
          >
            {title || 'Something went wrong'}
          </Text>
        </ErrorContentDiv>
        <ErrorContentDiv>
          <Text
            weight={400}
            size="md"
            mb={20}
            align="center"
          >
            {message ||
              'Sorry, the page you’re looking for doesn’t exist. If you think something is broken, report a problem.'}
          </Text>
        </ErrorContentDiv>
        {import.meta.env.MODE === 'development' && stack && (
          <p>
            <pre style={{ maxWidth: 600, overflow: 'auto', fontSize: 14 }}>{stack}</pre>
          </p>
        )}
        {!hideButton && (
          <ErrorContentDiv>
            <Button
              size="md"
              onClick={() => {
                if (buttonHandler) {
                  buttonHandler();
                } else {
                  window.open(`${window.location.protocol}//${window.location.host}/signup`, '_blank');
                }
              }}
            >
              {buttonText || 'Signup'}
            </Button>
          </ErrorContentDiv>
        )}
      </ErrorContent>
      <ErrorGraphic>{image || <Error404Graphic />}</ErrorGraphic>
    </ErrorContentWrapper>
  );
};
