import { FullPageLoading } from '@components/Loader/FullPageLoading';
import { accountService } from '@containers/Settings-And-Members/services/account.service';
import { workspaceService } from '@containers/Settings-And-Members/services/workspace.service';
import { useWindowEvent } from '@getonnet/tixio-ui-hooks';
import { withDevTools } from '@poly-state/core';
import { mapQuickSaveWithCustomID } from '@utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { globalStoreCookie } from './global-store-cookie.service';
import { GlobalStore, GlobalStoreContext } from './global.store';

//!todo router {get rid of global store variable usage and remove this}
export const globalStore = new GlobalStore();

export const GlobalStoreProvider: FC = ({ children }) => {
  const { mutateAsync: getMe } = accountService.useGetMeWithToken();
  const { mutateAsync: selectWorkspace } = workspaceService.useSelectWorkspace();

  const [ready, setReady] = useState(false);
  //!todo router { create an error state, do not render app if any of the below api calls fail}

  const init = useCallback(async () => {
    setReady(false);
    const cookies = globalStoreCookie.getCookies();

    if (!cookies) {
      return globalStore;
    }

    const tokens = {
      accessToken: cookies.accessToken,
      refreshToken: cookies.refreshToken,
    };

    const loginResponse = await getMe(tokens).catch((e) => {
      console.log(e);
      return undefined;
    });

    if (!loginResponse) {
      return globalStore;
    }

    const currentCompany = loginResponse.workspaces.find((company) => company.id === cookies.selectedCompanyID);

    if (!currentCompany) {
      globalStoreCookie.setGlobalStoreCookie((p) => {
        if (!p) {
          throw new Error('Expected default cookie to be set');
        }

        return {
          ...p,
          selectedCompanyID: null,
        };
      });

      return globalStore;
    }

    const selectResponse = currentCompany
      ? await selectWorkspace({ workspaceID: currentCompany.id, ...tokens }).catch((e) => {
          console.log(e);
          return null;
        })
      : null;

    globalStore.setState({
      authenticated: true,
      accessToken: cookies.accessToken,
      refreshToken: cookies.refreshToken,
      workspaces: loginResponse.workspaces,
      currentWorkspace: selectResponse?.workspaceInfo || null,
      user: {
        ...loginResponse.user,
        quickSave: loginResponse.user.quickSave.map((item) => {
          return {
            ...item,
            links: mapQuickSaveWithCustomID(item.links),
          };
        }),
      },
      billingInfo: selectResponse?.billing || null,
      showOverly: false,
      language: 'en',
      userPreferences: selectResponse?.userPreferences || null,
      isSwitchingWorkspace: false,
    });
  }, [getMe, selectWorkspace]);

  useEffect(() => {
    init().then(() => setReady(true));
  }, [init]);

  useEffect(() => {
    if (!import.meta.env.DEV) return;

    withDevTools(globalStore, 'TIXIO_GLOBAL_STORE');

    return () => {
      // console.warn('redux devtools connection left dangling', conn);
    };
  }, []);

  useWindowEvent('HYDRATE', () => init().then(() => setReady(true)));

  if (!ready)
    return (
      <FullPageLoading
        key="test"
        hint="Please wait while we load our resources"
        progress={40}
      />
    );
  return <GlobalStoreContext.Provider value={globalStore}>{children}</GlobalStoreContext.Provider>;
};
